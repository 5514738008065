import React from 'react';
import sanitizeHtml from 'sanitize-html';

import GreenStripe from '../../components/GreenStripe';
import Headline from '../../components/headline/Headline';
import { Section } from '../../components/Section';
import { SEO } from '../../components/Seo';
import { Submenu } from '../../components/Submenu';
import { AllMarkdownRemarkQueryResult } from '../../interfaces/AllMarkdownRemark';
import { MarkdownFileNode } from '../../interfaces/MarkdownFileNode';
import { PageProps } from '../../interfaces/PageProps';
import { allMarkdownRemark } from '../../queries/AllMarkdownRemark';
import { Container, Description } from '../../styles/CommonStyledComponents';
import { decodeUri } from '../../utils/DecodeUri';
import { findMarkdownFileByMarkdownFiles } from '../../utils/FindMarkdownFileByMarkdownFiles';
import { findMarkdownFiles } from '../../utils/FindMarkdownFiles';
import { getKindergartenByPathname } from '../../utils/GetKindergartenByPathname';
import { theme } from '../../utils/Theme';

const ParentsToolbox = (props: PageProps) => {
  const data: AllMarkdownRemarkQueryResult<MarkdownFileNode> | undefined = allMarkdownRemark<
    AllMarkdownRemarkQueryResult<MarkdownFileNode>
  >();

  const orderCollection: string[] =
    findMarkdownFiles<MarkdownFileNode>(
      data?.allMarkdownRemark,
      `settings/${getKindergartenByPathname(props.location.pathname)}`,
      'parents-toolbox',
    )[0]?.frontmatter?.collection?.map((c) => c.collectionName) || [];

  return (
    <>
      <SEO title={decodeUri(getKindergartenByPathname(props.location.pathname))} />
      <GreenStripe />
      <Submenu location={props.location} orderCollection={orderCollection}></Submenu>
      {orderCollection?.map((node: string, index: number) => (
        <Section
          id={
            findMarkdownFileByMarkdownFiles<MarkdownFileNode>(
              node,
              props.location,
              data?.allMarkdownRemark,
              'parents-toolbox',
            )?.frontmatter?.title
          }
          backgroundColor={index % 2 == 0 ? theme.colors.white : theme.colors.creamy}
          key={index}
        >
          <Headline
            arrowColor="arrowGreen"
            title={
              findMarkdownFileByMarkdownFiles<MarkdownFileNode>(
                node,
                props.location,
                data?.allMarkdownRemark,
                'parents-toolbox',
              )?.frontmatter?.title || ''
            }
          ></Headline>
          <Container>
            <Description
              dangerouslySetInnerHTML={{
                __html: sanitizeHtml(
                  findMarkdownFileByMarkdownFiles<MarkdownFileNode>(
                    node,
                    props.location,
                    data?.allMarkdownRemark,
                    'parents-toolbox',
                  )?.html || '',
                ),
              }}
            ></Description>
          </Container>
        </Section>
      ))}
    </>
  );
};

export default ParentsToolbox;
